<template>
    <div class="ruleContainer">
        <div class="ruleCheckbox">
            <b-form-checkbox v-model="active" name="checked-button" class="custom-checkbox" @change="changeRuleStatus(rule.id, !active)" switch>
            </b-form-checkbox>
        </div>
        <div class="ruleText">
             <p>{{ activeLanguage.orders_from }}</p>
             <v-autocomplete
                        :min-len="2"
                        v-model="client"
                        :items="clients"
                        :get-label="getLabel"
                        class="clientInput"
                        :style="autocompleteWidth"
                        :component-item='itemTemplate'
                        @update-items="updateClientList"
                        @item-selected="clientSelected"
            >
            </v-autocomplete>
             <p>{{ activeLanguage.are }}</p>
             <p>
                 <select name="amount" id="selectedAmount" class="select" :style="selectedAmountWidth" v-model="selectedAmount" @change="showBtns">
                    <option value="_full" style="selectDropdown">{{ activeLanguage.full }}</option>
                    <option value="_partial" style="selectDropdown">{{ activeLanguage.partially }}</option>
                    <option value="_all" style="selectDropdown">{{ activeLanguage.all }}</option>
                </select>
             </p>
             <p style="margin-right: 3px">{{ activeLanguage.paid_via }}</p>
             <div class="multiselect">
                <div class="selectBox" @click="showCheckboxes" v-click-outside="closeCheckboxes" :style="selectedPaymentWidth">
                    <select >
                        <option>{{ rule.selectedTypesText }}</option>
                    </select>
                <div class="overSelect"></div>
                </div>
                <div id="checkboxes" v-show="showCheckbox">
                    <div class="checkboxesInner">
                        <label for="Accrediting" >
                            <input type="checkbox"  v-model="accreditingCheckbox" @click="setType($event, 'Accrediting')" />
                                {{ activeLanguage.accrediting }}
                        </label>
                            
                        <label for="Added payment">
                            <input type="checkbox" v-model="addedPaymentCheckbox" @click="setType($event, 'Added payment')" />
                                {{ activeLanguage.added_payment }}
                        </label>
                        <label for="Customer">
                            <input type="checkbox" v-model="customerCheckbox" @click="setType($event, 'Customer')" />
                                {{ activeLanguage.customer }}
                        </label>
                    </div>
                </div>
            </div>
             <p style="margin-left: 3px">{{ activeLanguage.then_close_disputes_with_category }}</p>
             <input type="text" v-model="rule.dispute_categories" placeholder="Category" class="inputCategory select" :style="selectedCategoryWidth" style="max-width: 85px; margin-left: 3px">
        </div>
        <div class="ruleButtons">
            <div class="buttons" v-show="showButtons">
                <button class="btn btn-secondary dismissBtn" @click="dismiss">Dismiss</button>
                <button class="btn btn-primary saveBtn" @click="onSave">Save</button>
            </div>
            <div class="deleteRule" @click="deleteRule">
                <i class="fas fa-times-circle"></i>
            </div>

        </div>
    </div>
    
</template>

<script>
    import {mapGetters} from 'vuex'
    import ClientItemTemplate from '@/components/common/reusable/ClientItemTemplate.vue'

    export default {
        name: 'AutomationRulesTable',
        data() {
            return {
                client: {
                    username: `All Clients`,
                    id: null
                    },
                clients: [],
                itemTemplate: ClientItemTemplate,
                category: '',
                selectedTypes: [],
                selectedTypesText: '',
                selectedAmount: '',
                multiselectExpanded: false,
                showCheckbox: false,
                accreditation_types: [],
                accreditingCheckbox: false,
                addedPaymentCheckbox: false,
                customerCheckbox: false,
                dispute_categories: '',
                active: false,
                showButtons: false,
                initialRule: {},
                selectedAmountWidth: ''
            }
        },
        props: {
            rule: {
                type: Object
            }
        },
        watch: {
            'rule.dispute_categories': function(oldVal, newVal) {
                if (oldVal !== newVal) {
                    if (oldVal !== this.dispute_categories) {
                        this.showButtons = true    
                    } else {
                        this.showButtons = false
                    }
                }
                if(this.rule.dispute_categories !== oldVal && newVal !== oldVal) {
                    this.rule.dispute_categories = newVal.replace(/\s+/g, ',')
                }
            },
            selectedAmount: function(oldVal, newVal) {
                
                if (oldVal !== newVal) {
                    this.rule.selectedAmount = oldVal
                
                    if (this.selectedAmount && this.rule.selectedAmount) {
                    switch(true) {
                        case this.rule.selectedAmount === '_all' :
                            this.selectedAmountWidth = `width: ${this.activeLanguage.all.length - 3.5}ch; padding-left: 3px`
                            break;
                        case this.rule.selectedAmount === '_partial' :
                            this.selectedAmountWidth = `width: ${this.activeLanguage.partially.length - 1}ch; padding-left: 3px`
                            break;
                        case this.rule.selectedAmount === '_full' :
                            this.selectedAmountWidth = `width: ${this.activeLanguage.full.length}ch; padding-left: 3px`
                            break;
                        }
                    }
                }
            }
        },
        methods: {
            changeRuleStatus(id, status) {
                disputeAxios
                    .patch(`api/dispute-closing-rules/rule/${id}/${status}`)
			        .then(({ data }) => {
                        this.$emit('refreshRule')
			        })
			        .catch(err => console.error(err))
            },
            updateClientList(val) {
		        billinkAxios
				        .post('app/clients/list', {username: val})
				        .then(({data}) => {
                            this.clients = data.clients
                            this.showButtons = true
                            }
                            )
				        .catch(err => console.error(err))
	        },
	        clientSelected(val) {
		        this.client = {
                    username: val.username,
                    id: val.id
                }
	        },
	        getLabel (item) {
		        return !!item ? item.username : ''
	        },
            getClient(){
                if (!this.rule.client_id) {
                    this.client = {
                        username: `All Clients`,
                        id: null
                    }
                } else {
                    billinkAxios
				        .post('app/clients/list', {id: this.rule.client_id})
				        .then(({data}) => 
                            this.client = {
                             username:  data.clients[0].username,
                             id: data.clients[0].id  
                            })
				        .catch(err => console.error(err))
                }
            },
            setInitialData(rule) {
                this.initialRule = this.rule
                this.getClient()
                this.dispute_categories = rule.dispute_categories
                this.active = rule.active
                rule.selectedTypes = []
                rule.selectedTypesText = rule.selectedTypesText ? rule.selectedTypesText : rule.selectedTypesText = ''
                rule.accreditation_types.map((item) => {
                    switch(true) {
                        case item.includes('_all') :
                            rule.selectedAmount = '_all'
                            break;
                        case item.includes('_partial') :
                            rule.selectedAmount = '_partial'
                            break;
                        case item.includes('_full') :
                            rule.selectedAmount = '_full'
                            break;
                    }

                    switch(true) {
                        case item.includes('accreditation') :
                            this.accreditingCheckbox = true
                            rule.selectedTypes.push('accreditation')
                            rule.selectedTypesText = rule.selectedTypesText ? rule.selectedTypesText + `, ${this.activeLanguage.accrediting}` : rule.selectedTypesText + `${this.activeLanguage.accrediting}`
                            break;
                        case item.includes('payment') :
                            this.addedPaymentCheckbox = true
                            rule.selectedTypes.push('payment')
                            rule.selectedTypesText = rule.selectedTypesText ? rule.selectedTypesText + `, ${this.activeLanguage.added_payment}` : rule.selectedTypesText + `${this.activeLanguage.added_payment}`
                            break;
                        case item.includes('customer') :
                            this.customerCheckbox = true
                            rule.selectedTypes.push('customer')
                            rule.selectedTypesText = rule.selectedTypesText ? rule.selectedTypesText + `, ${this.activeLanguage.customer}` : rule.selectedTypesText + `${this.activeLanguage.customer}`
                            break;
                    }
                })
                this.selectedAmount = rule.selectedAmount
                this.selectedTypesText = rule.selectedTypesText
                this.rule = rule

            },
            showCheckboxes() {
                this.showCheckbox = !this.showCheckbox
            },
            closeCheckboxes() {
                this.showCheckbox = false
            },
            setType(e, type) {
                this.rule.selectedTypes = this.rule.selectedTypes ? this.rule.selectedTypes : this.rule.selectedTypes = []
                this.rule.selectedTypesText = this.rule.selectedTypesText ? this.rule.selectedTypesText : this.rule.selectedTypesText = ''
                let checked = e.target.checked
                let formattedText = this.rule.selectedTypesText
                let singleElText = ''
                switch(true) {
                        case checked && type === 'Accrediting' :
                            this.accreditingCheckbox = true
                            this.rule.selectedTypes.push('accreditation')
                            this.rule.selectedTypesText = this.rule.selectedTypesText ? this.rule.selectedTypesText + `, ${this.activeLanguage.accrediting}` : this.rule.selectedTypesText + `${this.activeLanguage.accrediting}`
                            break;
                        case checked && type === 'Added payment' :
                            this.addedPaymentCheckbox = true
                            this.rule.selectedTypes.push('payment')
                            this.rule.selectedTypesText = this.rule.selectedTypesText ? this.rule.selectedTypesText + `, ${this.activeLanguage.added_payment}` : this.rule.selectedTypesText + `${this.activeLanguage.added_payment}`
                            break;
                        case checked && type === 'Customer' :
                            this.customerCheckbox = true
                            this.rule.selectedTypes.push('customer')
                            this.rule.selectedTypesText = this.rule.selectedTypesText ? this.rule.selectedTypesText + `, ${this.activeLanguage.customer}` : this.rule.selectedTypesText + `${this.activeLanguage.customer}`
                            break;
                    }

                switch(true) {
                        case !checked && type === 'Accrediting' :
                            this.accreditingCheckbox = false
                            this.rule.selectedTypes = this.rule.selectedTypes.filter(e => e !== 'accreditation')
                            formattedText = this.rule.selectedTypesText.includes(`${this.activeLanguage.accrediting}, `) ? this.rule.selectedTypesText.replace(`${this.activeLanguage.accrediting}, `, "") : this.rule.selectedTypesText.replace(`${this.activeLanguage.accrediting}`, "")
                            this.rule.selectedTypesText = formattedText
                            break;
                        case !checked && type === 'Added payment' :
                            this.addedPaymentCheckbox = false
                            this.rule.selectedTypes = this.rule.selectedTypes.filter(e => e !== 'payment')
                            formattedText = this.rule.selectedTypesText.includes(`${this.activeLanguage.added_payment}, `) ? this.rule.selectedTypesText.replace(`${this.activeLanguage.added_payment}, `, "") : this.rule.selectedTypesText.replace(`${this.activeLanguage.added_payment}`, "")
                            this.rule.selectedTypesText = formattedText
                            break;
                        case !checked && type === 'Customer' :
                            this.customerCheckbox = false
                            this.rule.selectedTypes = this.rule.selectedTypes.filter(e => e !== 'customer')
                            formattedText = formattedText = this.rule.selectedTypesText.includes(`${this.activeLanguage.customer}, `) ? this.rule.selectedTypesText.replace(`${this.activeLanguage.customer}, `, "") : this.rule.selectedTypesText.replace(`${this.activeLanguage.customer}`, "")
                            this.rule.selectedTypesText = formattedText
                            break;
                    }

                    if(this.rule.selectedTypesText[this.rule.selectedTypesText.length - 1] === ' ') {
                        singleElText = this.rule.selectedTypesText.substring(0, this.rule.selectedTypesText.length - 2)
                        this.rule.selectedTypesText = singleElText
                    }

                    this.showButtons = true
            },
            deleteRule() {
                this.$emit('deleteRule')
            },
            dismiss() {
                this.showButtons = false
                this.rule.client_id = this.initialRule.client_id
                this.rule.selectedAmount = this.selectedAmount
                this.rule.active = this.initialRule.active
                this.rule.dispute_categories = this.dispute_categories
                this.rule.id = this.initialRule.id
                this.rule.selectedTypesText = this.selectedTypesText
                this.rule.selectedTypes = this.selectedTypes
            },
            onSave() {
                this.showButtons = false
                this.rule.client_id = this.client.id
                this.$emit('updateRule')
            },
            showBtns() {
                this.showButtons = true
            }

        },
        mounted() {
            this.setInitialData(this.rule)

        },
        components: {
        },
        computed: {
            ...mapGetters({
                paginationLanguage: 'getOrdersLanguage',
                activeLanguage: 'getAutomationRulesLanguage'
            }),
            autocompleteWidth() {
                if (this.client && this.client.username) {
                    if (this.client.username === 'All Clients') {
                        return `width: 72px`
                    } else {
                        return `width: ${this.client.username.length}ch`
                    }
                    
                } else {
                    return `width: 50px`
                }
            },
            selectedCategoryWidth() {
                return `width: ${this.rule.dispute_categories.length + 2}ch`
            },
            selectedPaymentWidth() {
                if (this.rule.selectedTypesText) {
                    return `width: ${this.rule.selectedTypesText.length}ch + 5px`
                }
            }
        }
    }

</script>

<style scoped>
    p {
        margin-top: auto;
        margin-bottom: auto;
    }

    .select {
        border: none;
        text-decoration: underline;
        font-weight: bold;
    }

    .ruleContainer {
        display: flex;
        flex-direction: row;
    }

    .ruleText {
        display: inline-flex;
        width: 100%;
    }

    .ruleCheckbox {
        position: relative;
        bottom: 0.6rem;
    }

    div.ruleContainer.v-autocomplete-input-group.v-autocomplete-selected {
        width: inherit;
    }

    div.ruleContainer.v-autocomplete-input-group.v-autocomplete-selected.v-autocomplete-input {
        border: none;
        padding: 0;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
    }
    .v-autocomplete {
        margin-left: 3px;
        /* margin-right: 3px; */
        z-index: auto;
    }

    .v-autocomplete div input {
        border: none;
        padding: 0;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
    }

    .v-autocomplete .v-autocomplete-selected {
        width: inherit;
    }

    >>>.v-autocomplete-selected {
        width: inherit;
    }

    >>>.v-autocomplete .v-autocomplete-input {
        border: none;
        padding: 0;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        text-decoration: underline;
        font-weight: bolder;
        height: 34px;
    }

    >>>.v-autocomplete-list {
        width: 150px;
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        z-index: 100;
    }

    .multiselect {
        margin-top: auto;
        margin-bottom: auto;
        min-height: 0;
        width: auto;
    }

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
  font-weight: bold;
  border: none;
  text-decoration: underline;
  font-size: 14px;
}

.selectBox select option {
  width: 100%;
  font-weight: bold;
  border: none;
  text-decoration: underline;
  font-size: 14px;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#checkboxes {
  display: block;
  border: 1px #dadada solid;
  z-index: 10;
  position: relative;
  height: 0px;
  width: inherit;
  background-color: #fff;
}

#checkboxes label {
  display: block;
  color: black;
  font-size: 14px;
}

#checkboxes label:hover {
  background-color: #1e90ff;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

select:focus {
    border: none !important;
    box-shadow: none !important;
}

input[type="text"]:focus {
    border: none !important;
    box-shadow: none !important;
}

.checkboxesInner {
    background-color: #fff;
    border: 1px #dadada solid;
    width: fit-content;
}

.ruleButtons {
    margin-left: auto;
    display: flex;
}

.buttons {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    margin-bottom: auto;
}

.dismissBtn {
    padding: 2px;
    font-size: 14px;
    text-transform: capitalize;
    height: 25px;
}

.saveBtn {
    padding: 2px;
    font-size: 14px;
    text-transform: capitalize;
    width: 60px;
    margin-left: 6px;
    margin-right: 10px;
    height: 25px;
}

.deleteRule {
        display: flex;
        font-size: 16px;
        margin-top: 0.5rem;
    }

    .deleteRule p {
            font-weight: 700;
            text-decoration: underline;
            margin-bottom: 0.5rem;
    }

    .deleteRule i {
            width: 22px;
            margin-top: 3px;
    }

    .deleteRule:hover {
        cursor: pointer;
    }

    .selectDropdown {
        background-color: #fff;
        border: 1px #dadada solid;
    }

    
</style>