<template>
    <div class="mainRulesContainer">
        <div v-for="rule in rules" class="rule">
            <AutomationRulesTableRowVue :rule="rule" @updateRule="updateRule(rule)" :key="rule.id" @deleteRule="deleteRule(rule.id)" @dismiss="onDismiss" @refreshRule="refreshRule" />
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import Pagination from '@/components/common/reusable/Pagination.vue'
    import PaginationCount from '@/components/common/reusable/PaginationCount.vue'
    import queryString from 'query-string'
    import AutomationRulesTableRowVue from './AutomationRulesTableRow.vue'

    export default {
        name: 'AutomationRulesTable',
        data() {
            return {
            }
        },
        props: {
            rules: {
                type: Array
            }
        },
        methods: {
            deleteRule(id) {
                disputeAxios
                    .delete(`api/dispute-closing-rules/delete/${id}`)
			        .then(({ data }) => {
                        this.$emit('getRulesList')
                        })
			        .catch(err => console.error(err))
            },
            changeRuleStatus(id, status) {
                disputeAxios
                    .patch(`api/dispute-closing-rules/rule/${id}/${status}`)
			        .then(({ data }) => {
			        })
			        .catch(err => console.error(err))
            },
            showOptions(rule) {
                if (rule && !!rule.showOptions) {
                    rule.showOptions = false
                } else {
                    rule.showOptions = true
                }
                this.$forceUpdate()
            },
            allowRuleEdit(rule) {
                rule.allowEdit = true
                this.$forceUpdate()
            },
            updateRule(rule) {
                let accTypes = []
                rule.selectedTypes.map((item) => {
                    accTypes.push(`${item + rule.selectedAmount}`)
                })
                let data = {
                    accreditation_types: accTypes.toString(),
                    dispute_categories: rule.dispute_categories,
                    client_id: rule.client_id ? rule.client_id : null
                }
                disputeAxios
			        .patch(`api/dispute-closing-rules/rule/${rule.id}`, queryString.stringify(data))
			        .then(({ data }) => {
                        this.$emit('getRulesList')
                        rule.allowEdit = false
                        window.location.reload()
			        })
			        .catch(err => console.error(err))
            },
            onDismiss() {
                this.$emit('getRulesList')
            },
            refreshRule() {
                this.$emit('getRulesList')
            }

        },
        mounted() {

        },
        components: {
            AutomationRulesTableRowVue
        },
        computed: {
            ...mapGetters({
                paginationLanguage: 'getOrdersLanguage'
            })
        }
    }

</script>

<style scoped>
    
    .rulesTh{
        padding: 0px;
    }
    .rulesTr {
        display:flex;
    }
    .automationBtn {
        float: right;
        margin-right: 50px;
        margin-left: auto;
    }
    .delete {
        background: red;
    }

    .rule {
        border: 1px solid lightgray;
        border-radius: 15px;
        padding: 15px;
        margin-top: 25px;
    }
</style>