<template>
    <div class="show collapse" id="addCollapse">
        <div class="filter-content">
            <form @submit.prevent="onRuleAdd()">
                <div class="form-row">
                    <div class="form-group">
                        <div id="event_period">
                            <div class="form-row">

                                <div class="ruleText">
                                    <p>{{ activeLanguage.orders_from }}</p>
                                    <v-autocomplete
                                        :min-len="2"
                                        v-model="client"
                                        :items="clients"
                                        :get-label="getLabel"
                                        class="clientInput"
                                        :style="autocompleteWidth"
                                        :component-item='itemTemplate'
                                        @update-items="updateClientList"
                                        @item-selected="clientSelected"
                                    >
                                    </v-autocomplete>
                                    <p>{{ activeLanguage.are }}</p>
                                    <p>
                                        <select name="amount" id="selectedAmount" class="select" :style="selectedAmountWidth" v-model="selectedAmount">
                                            <option value="_full" style="selectDropdown">{{ activeLanguage.full }}</option>
                                            <option value="_partial" style="selectDropdown">{{ activeLanguage.partially }}</option>
                                            <option value="_all" style="selectDropdown">{{ activeLanguage.all }}</option>
                                        </select>
                                    </p>
                                    <p style="margin-right: 3px">{{ activeLanguage.paid_via }}</p>
                                    <div class="multiselect">
                                        <div class="selectBox" @click="showCheckboxes" v-click-outside="closeCheckboxes" :style="selectedPaymentWidth">
                                            <select >
                                                <option>{{ selectedTypesText }}</option>
                                            </select>
                                        <div class="overSelect"></div>
                                        </div>
                                        <div id="checkboxes" v-show="showCheckbox">
                                            <div class="checkboxesInner">
                                                <label for="Accrediting" >
                                                    <input type="checkbox"  v-model="accreditingCheckbox" @click="setType($event, 'Accrediting')" />
                                                        {{ activeLanguage.accrediting }}
                                                </label>
                                                    
                                                <label for="Added payment">
                                                    <input type="checkbox" v-model="addedPaymentCheckbox" @click="setType($event, 'Added payment')" />
                                                        {{ activeLanguage.added_payment }}
                                                </label>
                                                <label for="Customer">
                                                    <input type="checkbox" v-model="customerCheckbox" @click="setType($event, 'Customer')" />
                                                        {{ activeLanguage.customer }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <p>{{ activeLanguage.then_close_disputes_with_category }}</p>
                                    <input type="text" v-model="category" class="inputCategory select" :style="selectedCategoryWidth" style="max-width: 200px; margin-left: 3px">
                                </div>

                                <div class="form-group col-12 mt-2" style="textAlign: right; marginBottom: 0px">
                                    <button type="button" @click="onClose" class="btn btn-secondary">Close</button>
                                    <button type="submit" class="btn ml-1">Add rule</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from 'vuex'
    import ClientItemTemplate from '@/components/common/reusable/ClientItemTemplate.vue'
    import queryString from 'query-string'

    export default {
        name: 'AddRule',
        data() {
            return {
                client: {
                    username: `All Clients`,
                    id: null
                    },
                clients: [],
                itemTemplate: ClientItemTemplate,
                category: 'Category',
                selectedTypes: ["accreditation"],
                selectedAmount: '_full',
                showCheckbox: false,
                selectedTypesText: '',
                accreditingCheckbox: true,
                addedPaymentCheckbox: false,
                customerCheckbox: false,
                selectedAmountWidth: ''

            }
        },
        watch: {
            category: function(oldVal, newVal) {
                if(this.category !== oldVal && newVal !== oldVal) {
                    this.category = newVal.replace(/\s+/g, ',')
                }
            },
            selectedAmount: function(oldVal, newVal) {
                
                if (oldVal !== newVal) {
                    switch(true) {
                        case this.selectedAmount === '_all' :
                            this.selectedAmountWidth = `width: ${this.activeLanguage.all.length - 3.5}ch; padding-left: 3px`
                            break;
                        case this.selectedAmount === '_partial' :
                            this.selectedAmountWidth = `width: ${this.activeLanguage.partially.length - 1}ch; padding-left: 3px`
                            break;
                        case this.selectedAmount === '_full' :
                            this.selectedAmountWidth = `width: ${this.activeLanguage.full.length}ch; padding-left: 3px`
                            break;
                        }
                    
                }
            }
        },
        methods: {
            showCheckboxes() {
                this.showCheckbox = !this.showCheckbox
            },
            closeCheckboxes() {
                this.showCheckbox = false
            },
            setType(e, type) {
                this.selectedTypes = this.selectedTypes ? this.selectedTypes : this.selectedTypes = []
                this.selectedTypesText = this.selectedTypesText ? this.selectedTypesText : this.selectedTypesText = ''
                let checked = e.target.checked
                let formattedText = this.selectedTypesText
                let singleElText = ''
                switch(true) {
                        case checked && type === 'Accrediting' :
                            this.accreditingCheckbox = true
                            this.selectedTypes.push('accreditation')
                            this.selectedTypesText = this.selectedTypesText ? this.selectedTypesText + `, ${this.activeLanguage.accrediting}` : this.selectedTypesText + `${this.activeLanguage.accrediting}`
                            break;
                        case checked && type === 'Added payment' :
                            this.addedPaymentCheckbox = true
                            this.selectedTypes.push('payment')
                            this.selectedTypesText = this.selectedTypesText ? this.selectedTypesText + `, ${this.activeLanguage.added_payment}` : this.selectedTypesText + `${this.activeLanguage.added_payment}`
                            break;
                        case checked && type === 'Customer' :
                            this.customerCheckbox = true
                            this.selectedTypes.push('customer')
                            this.selectedTypesText = this.selectedTypesText ? this.selectedTypesText + `, ${this.activeLanguage.customer}` : this.selectedTypesText + `${this.activeLanguage.customer}`
                            break;
                    }

                switch(true) {
                        case !checked && type === 'Accrediting' :
                            this.accreditingCheckbox = false
                            this.selectedTypes = this.selectedTypes.filter(e => e !== 'accreditation')
                            formattedText = this.selectedTypesText.includes(`${this.activeLanguage.accrediting}, `) ? this.selectedTypesText.replace(`${this.activeLanguage.accrediting}, `, "") : this.selectedTypesText.replace(`${this.activeLanguage.accrediting}`, "")
                            this.selectedTypesText = formattedText
                            break;
                        case !checked && type === 'Added payment' :
                            this.addedPaymentCheckbox = false
                            this.selectedTypes = this.selectedTypes.filter(e => e !== 'payment')
                            formattedText = this.selectedTypesText.includes(`${this.activeLanguage.added_payment}, `) ? this.selectedTypesText.replace(`${this.activeLanguage.added_payment}, `, "") : this.selectedTypesText.replace(`${this.activeLanguage.added_payment}`, "")
                            this.selectedTypesText = formattedText
                            break;
                        case !checked && type === 'Customer' :
                            this.customerCheckbox = false
                            this.selectedTypes = this.selectedTypes.filter(e => e !== 'customer')
                            formattedText = formattedText = this.selectedTypesText.includes(`${this.activeLanguage.customer}, `) ? this.selectedTypesText.replace(`${this.activeLanguage.customer}, `, "") : this.selectedTypesText.replace(`${this.activeLanguage.customer}`, "")
                            this.selectedTypesText = formattedText
                            break;
                    }

                    if(this.selectedTypesText[this.selectedTypesText.length - 1] === ' ') {
                        singleElText = this.selectedTypesText.substring(0, this.selectedTypesText.length - 2)
                        this.selectedTypesText = singleElText
                    }

                    this.showButtons = true
            },
            updateClientList(val) {
		        billinkAxios
				        .post('app/clients/list', {username: val})
				        .then(({data}) => this.clients = data.clients)
				        .catch(err => console.error(err))
	        },
	        clientSelected(val) {
		        this.client = {
                    username: val.username,
                    id: val.id
                }
	        },
	        getLabel (item) {
		        return !!item ? item.username : ''
	        },
            onRuleAdd() {
                let accreditation = []

                this.selectedTypes.map((item) => {
                    accreditation.push(`${item + this.selectedAmount}`)
                })

                let data = {
                    accreditation_types: accreditation.toString(),
                    dispute_categories: this.category,
                    client_id: this.client.id ? this.client.id : null
                }
                disputeAxios
			        .post("api/dispute-closing-rules/add", queryString.stringify(data))
			        .then(({ data }) => {
                        this.$emit('getRulesList')
                        this.client = {
                            username: `All Clients`,
                            id: null
                        }
                        this.category =  ''
                        this.selectedTypes = null
                        this.selectedAmount = null
                        window.location.reload()
                        this.$emit('close')
			        })
			        .catch(err => console.error(err))
            },
            onClose() {
                this.category = ''
                this.client = {
                    username: `All Clients`,
                    id: null
                    }
                this.$emit('close')
            }

        },
        mounted() {
            this.selectedTypesText = this.activeLanguage.accrediting
            switch(true) {
                        case this.selectedAmount === '_all' :
                            this.selectedAmountWidth = `width: ${this.activeLanguage.all.length - 3.5}ch; padding-left: 3px`
                            break;
                        case this.selectedAmount === '_partial' :
                            this.selectedAmountWidth = `width: ${this.activeLanguage.partially.length - 1}ch; padding-left: 3px`
                            break;
                        case this.selectedAmount === '_full' :
                            this.selectedAmountWidth = `width: ${this.activeLanguage.full.length}ch; padding-left: 3px`
                            break;
                        }
        },
        components: {
            ClientItemTemplate
        },
        computed: {
            ...mapGetters({
                activeLanguage: 'getAutomationRulesLanguage'
            }),
            autocompleteWidth() {
                if (this.client && this.client.username) {
                    if (this.client.username === 'All Clients') {
                        return `width: 72px`
                    } else {
                        return `width: ${this.client.username.length}ch`
                    }
                } else {
                    return `width: 50px`
                }
            },
            selectedCategoryWidth() {
                return `width: ${this.category.length + 2}ch`
            },
            selectedPaymentWidth() {
                if (this.selectedTypesText) {
                    return `width: ${this.selectedTypesText.length - 1}ch + 5px`
                }
            }
        }
    }

</script>

<style scoped>
    p {
        margin-top: auto;
        margin-bottom: auto;
    }

    .select {
        border: none;
        text-decoration: underline;
        font-weight: bold;
    }

    .ruleContainer {
        display: flex;
        flex-direction: row;
    }

    .ruleText {
        display: inline-flex;
        width: 100%;
    }

    .ruleCheckbox {
        position: relative;
        bottom: 0.6rem;
    }

    div.ruleContainer.v-autocomplete-input-group.v-autocomplete-selected {
        width: inherit;
    }

    div.ruleContainer.v-autocomplete-input-group.v-autocomplete-selected.v-autocomplete-input {
        border: none;
        padding: 0;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
    }
    .v-autocomplete {
        margin-left: 3px;
        /* margin-right: 3px; */
        z-index: auto;
    }

    .v-autocomplete div input {
        border: none;
        padding: 0;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
    }

    .v-autocomplete .v-autocomplete-selected {
        width: inherit;
    }

    >>>.v-autocomplete-selected {
        width: inherit;
    }

    >>>.v-autocomplete .v-autocomplete-input {
        border: none;
        padding: 0;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        text-decoration: underline;
        font-weight: bolder;
        height: 34px;
    }

    >>>.v-autocomplete-list {
        width: 150px;
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        z-index: 100;
    }

    .multiselect {
        margin-top: auto;
        margin-bottom: auto;
        min-height: 0;
        width: auto;
    }

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
  font-weight: bold;
  border: none;
  text-decoration: underline;
  font-size: 14px;
  margin-right: 5px;
}

.selectBox select option {
  width: 100%;
  font-weight: bold;
  border: none;
  text-decoration: underline;
  font-size: 14px;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#checkboxes {
  display: block;
  border: 1px #dadada solid;
  z-index: 10;
  position: relative;
  height: 0px;
  width: inherit;
  background-color: #fff;
}

#checkboxes label {
  display: block;
  color: black;
  font-size: 14px;
}

#checkboxes label:hover {
  background-color: #1e90ff;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

select:focus {
    border: none !important;
    box-shadow: none !important;
}

input[type="text"]:focus {
    border: none !important;
    box-shadow: none !important;
}

.checkboxesInner {
    background-color: #fff;
    border: 1px #dadada solid;
    width: fit-content;
}

.ruleButtons {
    margin-left: auto;
    display: flex;
}

.buttons {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    margin-bottom: auto;
}

.dismissBtn {
    padding: 2px;
    font-size: 14px;
    text-transform: capitalize;
    height: 25px;
}

.saveBtn {
    padding: 2px;
    font-size: 14px;
    text-transform: capitalize;
    width: 60px;
    margin-left: 6px;
    margin-right: 10px;
    height: 25px;
}

.deleteRule {
        display: flex;
        font-size: 16px;
        margin-top: 0.5rem;
    }

    .deleteRule p {
            font-weight: 700;
            text-decoration: underline;
            margin-bottom: 0.5rem;
    }

    .deleteRule i {
            width: 22px;
            margin-top: 3px;
    }

    .deleteRule:hover {
        cursor: pointer;
    }

    .selectDropdown {
        background-color: #fff;
        border: 1px #dadada solid;
    }

    .form-row {
        margin-left: auto;
        margin-right: auto;
        width: 100%
    }
    .form-group {
        width: 100%
    }
</style>