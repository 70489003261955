<template>
    <div class="main">
        <div class="container disputeRules">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-xl-7">
                            <h1 class="row">Disputes closing automation rules</h1>
                        </div>
                        <div class="col-12 col-xl-5">
                            <div class="row btns">
                                <button class="btn float-right new-order-link hidden-xs ml-0" @click="showAddRuleBlock" v-show="!showAddRule">
                                    Add rule
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="filter-bar-container" v-show="showAddRule" style="marginTop: 40px">
                <div class="filter-bar">
                    <div>
                        <button class="btn btn-secondary" >Add Closing Rule</button>
                    </div>
                </div>

                <transition name="collapse">
                    <AddRule ref="addRule" @onRuleAdd="onRuleAdd" @getRulesList="getRulesList" @close="onAddClose" />
                </transition>
            </div>
            
            <AutomationRulesTable :rules="rules" @getRulesList="getRulesList"/>
            
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import Pagination from '@/components/common/reusable/Pagination.vue'
    import PaginationCount from '@/components/common/reusable/PaginationCount.vue'
    import AddRule from './AddRule.vue'
    import AutomationRulesTable from './AutomationRulesTable.vue'

    export default {
        name: 'AutomationRules',
        data() {
            return {
                showAddRule: false,
                webshop: [],
                paidAmount: '',
                paidVia: '',
                category: '',
                rules: null

            }
        },
        methods: {
            showAddRuleBlock() {
                this.showAddRule = !this.showAddRule
            },
            onRuleAdd() {
                disputeAxios
			        .post(`api/dispute-closing-rules/add`)
			        .then(({ data }) => {
                        this.rules = data
			        })
			        .catch(err => console.error(err))
            },
            onAddClose() {
                this.showAddRule = false
            },
            getRulesList() {
                disputeAxios
			        .get(`api/dispute-closing-rules/list`)
			        .then(({ data }) => {
                        this.rules = data
			        })
			        .catch(err => console.error(err))
            },
            deleteRule(id) {
                disputeAxios
                    .delete(`api/dispute-closing-rules/delete/${id}`)
			        .then(({ data }) => {
                        this.getRulesList()
			        })
			        .catch(err => console.error(err))
            },
            changeRuleStatus(id, status) {
                disputeAxios
                    .patch(`api/dispute-closing-rules/rule/${id}/${status}`)
			        .then(({ data }) => {
                        this.getRulesList()
			        })
			        .catch(err => console.error(err))
            }

        },
        mounted() {
            this.getRulesList()

        },
        components: {
            Pagination,
            PaginationCount,
            AddRule,
            AutomationRulesTable
        },
        computed: {
            ...mapGetters({
                paginationLanguage: 'getOrdersLanguage'
            })
        }
    }

</script>

<style scoped>

@media (min-width: 1200px) {
  .disputeRules {
    max-width: 1500px;
  }
}
    .btns {
        justify-content: flex-end;
    }

    .btn-group {
        margin: 0 5px;
    }

    >>> .b-dropdown .btn.btn-secondary {
        background: #ffff !important;
        padding: 0.25rem 1rem 0.5rem 1rem !important;
    }
</style>